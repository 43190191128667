import {
  ControllerStageDataManifest,
  ControllerVisibility,
  EditorSDK,
  GfppButtonConfig,
  InitialAppData,
} from '@wix/platform-editor-sdk';
import { panelUrlBuilder } from '../../utils/editor';
import componentConfig from './.component.json';
import { ActionDefinition } from './types';
import { i18nextCore } from '../../utils/i18n';
import { TFunction } from 'i18next';
import { CustomEventHandler, WidgetEditor } from '@wix/bob-widget-services';

const widgetId = componentConfig.id;

const SETTINGS_EV_ID = `${widgetId}:settings`;
export const SETTINGS_ADD_ID = `${widgetId}:settings:add`;
export const POPUP_SETTINGS = `${widgetId}:popup:settings`;

export const ACTION_IDS = {
  EMAIL_SETTINGS: `${widgetId}:email:settings`,
  PASSWORD_SETTINGS: `${widgetId}:password:settings`,
  SUBMIT_SETTINGS: `${widgetId}:submit:settings`,
};

function openSettingsPanel(editorSDK: EditorSDK, { componentRef }: any) {
  editorSDK.editor.openComponentPanel('token', {
    title: 'Widget Settings',
    url: panelUrlBuilder(editorSDK, componentRef, 'sm-platform-app'),
    height: 240,
    width: 500, // TODO: seems like the width doesn't work, we need to check it out
    componentRef,
  });
}

const widgetEventsHandler: CustomEventHandler = {
  widgetGfppClicked: {
    [SETTINGS_EV_ID]: (payload, editorSDK) => {
      return openSettingsPanel(editorSDK, payload);
    },
  },
};
type IOverrideGfppDefault = (options?: {
  settingsIcon?: GfppButtonConfig | ActionDefinition;
  settings?: GfppButtonConfig | ActionDefinition;
  mobileSettings?: GfppButtonConfig | ActionDefinition;
  mobileSettingsIcon?: GfppButtonConfig | ActionDefinition;
  mainAction2?: GfppButtonConfig;
}) => { desktop: any; mobile: any };

const overrideGfppDefaults: IOverrideGfppDefault = (options) => {
  const hidingLinkAndConnectButtons = {
    link: 'HIDE' as GfppButtonConfig,
    connect: 'HIDE' as GfppButtonConfig,
  };
  const iconButtons = {
    ...hidingLinkAndConnectButtons,
    settings: options?.settingsIcon,
  };
  const mobileIconButtons = {
    ...hidingLinkAndConnectButtons,
    settings: options?.mobileSettingsIcon || options?.settingsIcon,
  };
  const gfpp = {
    desktop: {
      mainAction1: options?.settings,
      mainAction2: options?.mainAction2,
      iconButtons,
    },
    mobile: {
      mainAction1: options?.mobileSettings || options?.settings,
      mobileIconButtons,
    },
  };
  return gfpp;
};

const popupController: (t: TFunction) => any = (t) => ({
  default: {
    visibility: 'NEVER' as ControllerVisibility,
    toggleShowOnAllPagesEnabled: false,
    connections: {
      popupContainer: {
        displayName: t('displayNames.popup'),
        firstTimeExperience: { hidden: true },
        gfpp: {
          desktop: {
            // Until we will have the time to solve the deep link I comment it out.
            // mainAction1: {
            //   label: t('actionLabels.settings'),
            //   actionId: POPUP_SETTINGS
            // },
          },
        },
      },
    },
  },
});

const widgetController = (t: TFunction) => ({
  default: {
    visibility: 'NEVER' as ControllerVisibility,
    toggleShowOnAllPagesEnabled: false,
    gfpp: {
      desktop: {
        // Currently we don't want to have a main action.
        // mainAction1: {
        //   actionId: SETTINGS_EV_ID,
        //   label: t('actionLabels.settings'),
        // },
        mainAction1: {
          actionId: SETTINGS_ADD_ID,
          label: t('actionLabels.addElements'),
        },
        helpId: '506376b7-f7ea-4542-b13d-c280e2ff8ee2',
      },
      mobile: {
        iconButtons: {},
        helpId: '506376b7-f7ea-4542-b13d-c280e2ff8ee2',
      },
    },
    displayName: t('displayNames.widget'),
    nickname: widgetId,
    connections: {
      title: {
        displayName: t('displayNames.title'),
        gfpp: overrideGfppDefaults({
          mainAction2: 'HIDE' as GfppButtonConfig,
        }),
      },
      // Keeping the subtitle in order to support old versions of the app where the subTitle is exist.
      subTitle: {
        displayName: t('displayNames.subTitle'),
        gfpp: overrideGfppDefaults({
          mainAction2: 'HIDE' as GfppButtonConfig,
        }),
      },
      signUpLink: {
        displayName: t('displayNames.signUpLink'),
        gfpp: overrideGfppDefaults({
          mainAction2: 'HIDE' as GfppButtonConfig,
        }),
      },
      email: {
        displayName: t('displayNames.email'),
        gfpp: overrideGfppDefaults({
          settingsIcon: { actionId: ACTION_IDS.EMAIL_SETTINGS },
          mobileSettings: 'HIDE' as GfppButtonConfig,
          mobileSettingsIcon: 'HIDE' as GfppButtonConfig,
          settings: {
            actionId: ACTION_IDS.EMAIL_SETTINGS,
            label: t('actionLabels.settings'),
          },
        }),
      },
      password: {
        displayName: t('displayNames.password'),
        gfpp: overrideGfppDefaults({
          settingsIcon: { actionId: ACTION_IDS.PASSWORD_SETTINGS },
          mobileSettings: 'HIDE' as GfppButtonConfig,
          mobileSettingsIcon: 'HIDE' as GfppButtonConfig,
          settings: {
            actionId: ACTION_IDS.PASSWORD_SETTINGS,
            label: t('actionLabels.settings'),
          },
        }),
      },
      submit: {
        displayName: t('displayNames.submit'),
        gfpp: overrideGfppDefaults({
          settings: {
            actionId: ACTION_IDS.SUBMIT_SETTINGS,
            label: t('actionLabels.submitSettings'),
          },
        }),
      },
      social: {
        displayName: t('displayNames.social'),
      },
      forgotPasswordLink: {
        displayName: t('displayNames.forgotPasswordLink'),
        gfpp: overrideGfppDefaults({
          mainAction2: 'HIDE' as GfppButtonConfig,
        }),
      },
      socialDividerLabel: {
        displayName: t('displayNames.socialDividerLabel'),
        gfpp: overrideGfppDefaults({
          mainAction2: 'HIDE' as GfppButtonConfig,
        }),
      },
      generalErrMsg: {
        displayName: t('displayNames.generalErrMsg'),
        gfpp: overrideGfppDefaults({
          mainAction2: 'HIDE' as GfppButtonConfig,
        }),
      },
    },
  },
});

const getWidgetStageData = async (
  initialAppData?: InitialAppData,
): Promise<ControllerStageDataManifest> => {
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:3200'
      : initialAppData!.editorScriptUrl.replace(
          '/editorScript.bundle.min.js',
          '',
        );
  const t = await i18nextCore(initialAppData!.languageCode || 'en', baseUrl);
  return {
    popupController: popupController(t),
    [widgetId]: widgetController(t),
  };
};

function createWidget(): WidgetEditor {
  return {
    type: widgetId,
    getEventHandlers() {
      return widgetEventsHandler;
    },
    async getStageData(initialAppData) {
      return getWidgetStageData(initialAppData);
    }
  };
}

export default createWidget();
